import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import Select from 'react-select'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { useSetState } from '../../../others/customHooks'
import { objectService } from '../../../../services/objectService'
import { buildingService } from '../../../../services/buildingService'
import { floorService } from '../../../../services/floorService'
import { roomService } from '../../../../services/roomService'
import { getQuantityString } from '../../../../utils/Others'
import TablePagination from '../../TablePagination'
import InputDebounce from '../../../items/InputDebounce'

const RoomsSmallTable = ({ onAdd, onRemove, selectedRoomsId, disabledWithContracts }) => {

    const [loading, setLoading] = useState(false)

    const [pagination, setPagination] = useSetState({
        page: 1,
        elementsCount: 20,
        fullCount: 0
    })

    const [fullCount, setFullCount] = useState(0)

    const [search, setSearch] = useState('')

    const [selectedOptions, setSelectedOptions] = useSetState({
        object: null,
        corps: null,
        floor: null
    })

    const [state, setState] = useSetState({
        objects: [],
        corps: [],
        floors: [],
        rooms: []
    })

    const objectOptions = useMemo(() => state.objects.map(createObjectOption), [state.objects])

    const corpsOptions = useMemo(() => state.corps.map(createCorpOption), [state.corps])

    const floorOptions = useMemo(() => state.floors.map(createFloorOption), [state.floors])

    const selectRoom = useCallback((id) => {
            if (selectedRoomsId.includes(id)) {
                onRemove(id)
            } else {
                const selectedRoom = state.rooms.find(item => item.id === id)
                if (selectedRoom) {
                    onAdd(selectedRoom)
                }
            }
        }
        , [state, selectedRoomsId, onRemove, onAdd])

    useEffect(() => {
        objectService.getAll().then(_ => {
            setState({ objects: _ })
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedOptions.object) {
            buildingService.getAllByObjectId(selectedOptions.object.id).then(_ => {
                setState({ corps: _ })
            })
        }
        // eslint-disable-next-line
    }, [selectedOptions.object])

    useEffect(() => {
        setLoading(true)
        //TODO: 04.12.22 21:09 Убрал querystring.stringify(....)
        const params = {
            objectsId: selectedOptions.object?.id ?? undefined,
            corpsId: selectedOptions.corps?.id ?? undefined,
            page: pagination.page,
            elementsCount: pagination.elementsCount,
            inputRoomName: search || undefined
        }

        roomService.getByPage(params)
            .then(({ data, fullCount }) => {
                setState({ rooms: data })
                setFullCount(fullCount)
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [selectedOptions, pagination, search])

    useEffect(() => {
        if (selectedOptions.corps) {
            floorService.getAll(selectedOptions.corps.id).then(_ => {
                setState({ floors: _ })
            })
        }
        // eslint-disable-next-line
    }, [selectedOptions.corps])
    return <>
        <MDBRow>
            <MDBCol sm={12} md={6} className="mt-3">
                <InputDebounce
                    group
                    type="text"
                    containerClass="m-0"
                    className="m-0"
                    outline
                    label="Название помещения"
                    valueDefault={search}
                    getValue={setSearch}
                />
            </MDBCol>
            <MDBCol sm={12} md={6} className="mt-3">
                <Select
                    placeholder="Объект"
                    options={objectOptions}
                    value={selectedOptions.object}
                    onChange={object => setSelectedOptions({ object, corps: null, floor: null })}
                />
            </MDBCol>
            <MDBCol sm={12} md={6} className="mt-3">
                <Select
                    placeholder="Корпус"
                    options={corpsOptions}
                    value={selectedOptions.corps}
                    onChange={corps => setSelectedOptions({ corps, floor: null })}
                    isDisabled={!selectedOptions.object}
                />
            </MDBCol>
            <MDBCol sm={12} md={6} className="mt-3">
                <Select
                    placeholder="Этаж"
                    options={floorOptions}
                    value={selectedOptions.floor}
                    onChange={floor => setSelectedOptions({ floor })}
                    isDisabled={!selectedOptions.corps}
                />
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol size={12}>
                {state.rooms.length || loading ? (
                    <MDBTable hover className="select-table smart-table select-table--scrollable table--small-padding mt-4">
                        <MDBTableHead>
                            <tr>
                                <th className="p-1">#</th>
                                <th className="p-1">Название</th>
                                <th className="p-1">Плановая аренда</th>
                                <th className="p-1">Площадь</th>
                                <th className="p-1">Контракт</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {state.rooms.map((room, index) => (
                                <tr
                                    key={room.id}
                                    onClick={() => selectRoom(room.id)}
                                    className={
                                        classNames(
                                            selectedRoomsId.includes(room.id) && 'selected',
                                            disabledWithContracts && !!room.contract && 'disabled'
                                        )
                                    }
                                >
                                    <td>{loading ? <Skeleton style={{ height: 16 }}/> : (pagination.elementsCount * (pagination.page - 1) + index + 1)}</td>
                                    <td>{loading ? <Skeleton style={{ height: 16 }}/> : room.name}</td>
                                    <td>{loading ?
                                        <Skeleton style={{ height: 16 }}/> : getQuantityString(room.amountRent)}</td>
                                    <td>{loading ?
                                        <Skeleton style={{ height: 16 }}/> : getQuantityString(room.square, 'м²')}</td>
                                    <td>{loading
                                        ? <Skeleton style={{ height: 16 }}/>
                                        : room.contract
                                            ? <Link to={`/contracts/${room.contract.id}`}>{room.contract.name}</Link>
                                            : '-'
                                    }
                                    </td>

                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>) :
                    <div>
                        <h4 className="d-flex justify-content-center p-4 text-center">
                            Нет данных с такими параметрами =( <br/> Попробуйте изменить критерии поиска.
                        </h4>
                    </div>}
            </MDBCol>
            <MDBCol size={12}>
                <TablePagination
                    {...{
                        showSelectCount: false,
                        page: pagination.page,
                        count: pagination.elementsCount,
                        fullCount: fullCount,
                        setCount: count => setPagination({ elementsCount: count }),
                        setPage: page => setPagination({ page })
                    }}
                />
            </MDBCol>
        </MDBRow>
    </>
}

export default RoomsSmallTable

const createObjectOption = item => ({
    value: item.id,
    label: item.name,
    id: item.id
})

const createCorpOption = item => ({
    value: item.id,
    label: item.corpName,
    id: item.id
})

const createFloorOption = item => ({
    value: item.id,
    label: item.floorName,
    id: item.id
})