import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import DashboardPage from './containers/dashboard/pages/DashboardPage'
import ReportsContainer from './containers/reports/ReportsContainer'
import {
    Accruals,
    AdminPanel,
    Appeals,
    Contracts,
    Employees,
    Events,
    Login,
    Object,
    Renters,
    Owners,
    Scores,
    Income,
    Rooms,
    RoomGroups
}                       from '../routes/RoutesAdditional'
import ProfileContainer from './containers/profile/ProfileContainer'
import NotFoundPage     from './errors/NotFoundPage'
import Help             from "./containers/help";

class Routes extends React.Component {
    render() {
        let body
        if (!this.props.isLoggedIn) body = Login
        else
            body = (
                <Switch>
                    <Route path="/404" exact component={NotFoundPage} />
                    <Route path="/main" exact component={DashboardPage} />
                    <Route path="/reports" exact component={ReportsContainer} />
                    <Route path="/objects" component={Object} />
                    <Route path="/owners" component={Owners} />
                    <Route path="/renters" component={Renters} />
                    <Route path="/employees" component={Employees} />
                    <Route path="/contracts" component={Contracts} />
                    <Route path="/appeals" component={Appeals} />
                    <Route path="/accruals" component={Accruals} />
                    <Route path="/events" component={Events} />
                    <Route path="/profile" exact component={ProfileContainer} />
                    <Route path="/admin-panel" component={AdminPanel} />
                    <Route path="/scores" component={Scores} />
                    <Route path="/income" component={Income} />
                    <Route path="/help" component={Help} />
                    <Route path="/rooms"  component={Rooms}/>
                    <Route path="/groups"  component={RoomGroups}/>
                    <Route path="/groups"  component={RoomGroups}/>
                    <Redirect exact from="/" to="/main" />
                    <Redirect exact from="/*" to="/404" />
                </Switch>
            )
        return body
    }
}

export default Routes
